import { useState } from 'react';
import { AssetV2 } from 'types/asset.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import AssetManagementService from '../services/asset-management.service';
import { useAssetManagementConfigContext } from '../context/asset-management-config.context';
import { useAMFetch, useAMNavigation, useAMAssetSelection } from '.';

const useAMRelease = (
    latestAsset: AssetV2<unknown, unknown>,
    onClose?: () => void,
    onSetFreshAsset?: (asset: AssetV2<unknown, unknown>) => void
): {
    releaseAsset: () => Promise<void>;
    waiting: boolean;
    confirmRelease: boolean;
    setConfirmRelease: React.Dispatch<React.SetStateAction<boolean>>;
    setAvailableAndRelease: () => Promise<void>;
    setDraftAndRetract: () => Promise<void>;
    handleReleaseClick: () => void;
} => {
    const { languageNameSpace, versioning } = useAssetManagementConfigContext();
    const { refreshLists } = useAMFetch();
    const { matchPathToStatus, assetId } = useAMNavigation();
    const { setSelectedAssets } = useAMAssetSelection(latestAsset._id);
    const [waiting, setWaiting] = useState(false);
    const [confirmRelease, setConfirmRelease] = useState(false);

    // Refresh available views after release
    const refreshAfterRelease = (response: AssetV2<unknown, unknown>) => {
        if (onSetFreshAsset) onSetFreshAsset(response);
        refreshLists('available');
        if (versioning) SnackbarUtils.success(Translation.get('snackbar.released', languageNameSpace));
        setWaiting(false);
        if (onClose) onClose();
        if (confirmRelease) setConfirmRelease(false);
    };

    // Release an asset that is allready available
    const releaseAsset = async () => {
        setWaiting(true);
        const updateOnlyMetadata = !assetId; // If we're not in detailview, we only want to update metadata, because the data in the list might be incomplete.
        await AssetManagementService.patchAsset(latestAsset, { updateOnlyMetadata });
        // We have to release the asset separately, because a release in the backend is only triggerd by a status change to available.
        const response = await AssetManagementService.releaseAsset(latestAsset._id);
        if (response) {
            refreshAfterRelease(response);
        }
    };

    // Set an asset to available, if this asset type has versioning, the API will release it.
    const setAvailableAndRelease = async () => {
        setWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...latestAsset, status: 'available' };
        const updateOnlyMetadata = !assetId; // If we're not in detailview, we only want to update metadata, because the data in the list might be incomplete.
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata });
        if (response) {
            refreshAfterRelease(response);
            refreshLists('draft');
            setSelectedAssets([]);
            matchPathToStatus('available');
        }
    };

    // Set an asset to draft, if this asset type has versioning, the API will retract it.
    const setDraftAndRetract = async () => {
        setWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...latestAsset, status: 'draft' };
        const updateOnlyMetadata = !assetId; // If we're not in detailview, we only want to update metadata, because the data in the list might be incomplete.
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata });
        if (response) {
            refreshLists('available');
            refreshLists('draft');
            setSelectedAssets([]);
            if (versioning) SnackbarUtils.success(Translation.get('snackbar.released', languageNameSpace));
            matchPathToStatus('draft');
            setWaiting(false);
            if (onClose) onClose();
            if (confirmRelease) setConfirmRelease(false);
        }
    };

    const handleReleaseClick = () => {
        if (latestAsset.status === 'available') {
            // We're releasing an asset that was released before, show confirmation or version comparison dialog.
            setConfirmRelease(true);
        } else {
            // Draft assets we can release without confirmation dialog.
            setAvailableAndRelease();
        }
    };

    return {
        releaseAsset,
        waiting,
        confirmRelease,
        setConfirmRelease,
        setAvailableAndRelease,
        setDraftAndRetract,
        handleReleaseClick
    };
};

export default useAMRelease;
