import { AssetV2 } from 'types/asset.type';
import EditorData from 'components/editor-data/EditorData';
import { convertCreativeV1ToV2, getCreativeDataModel, isCreativeBuilderCreative, isVisualStackCreative } from 'components/creative-management-v2/utilities';
import { EditCreativeUuidHelpers } from 'components/creative-management-v2/helpers/edit-creative-uuid-helpers';
import AssetManagementBaseService from './asset-management.base.service';
import { isCreativeAsset } from '../utilities';

export default class AssetManagementService extends AssetManagementBaseService {
    // Some asset types store their data outside Asset Management while being edited, If so get that data before sending them to the API
    static getExtendedAsset = (asset: AssetV2<unknown, unknown>): AssetV2<unknown, unknown> => {
        if (isCreativeAsset(asset)) {
            // If a creative is being edited, it stores it's data in the Campaign Editor, so we need to get it before sending it to the API.
            // In all other cases, we just use the asset as is.
            const uuid = EditCreativeUuidHelpers.getCreativeUuid();
            if (uuid) {
                const dataModel = getCreativeDataModel(uuid);
                if (isCreativeBuilderCreative(asset)) {
                    const creativeDataV1 = EditorData.getValueFromModel(dataModel);
                    if (creativeDataV1) {
                        const convertedCreative = convertCreativeV1ToV2(creativeDataV1);
                        const { data } = convertedCreative;

                        if (data) return { ...asset, data };
                    }
                }
                if (isVisualStackCreative(asset)) {
                    const editorData = EditorData.getValueFromModel(dataModel);
                    const data = { ...asset.data, templateInput: editorData };
                    if (data) {
                        return { ...asset, data };
                    }
                }
            }
        }
        return asset;
    };

    // When patching an asset, we want to send the right data with it.
    static async patchAsset(asset: AssetV2<unknown, unknown>, queryParams?: { updateOnlyMetadata?: boolean }): Promise<AssetV2<unknown, unknown>> {
        const extendedAsset = this.getExtendedAsset(asset);
        return await super.patchAsset(extendedAsset, queryParams);
    }
}
