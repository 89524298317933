import { Filters } from 'types/filter.type';
import { set } from 'lodash';

// Apply pre filters to the filters selected by the user.
const applyPreFilters = (filters?: Filters, preFilters?: { [key: string]: string[] }): Filters => {
    if (!filters && !preFilters) return {};
    if (!preFilters) return filters || {};
    if (!filters) return preFilters;
    if (!Object.keys(preFilters).length) return filters;
    Object.entries(preFilters).forEach(([preFilterKey, preFilterValue]) => {
        if (filters[preFilterKey]) {
            if (Array.isArray(filters[preFilterKey])) {
                const limitedValue = filters[preFilterKey].filter((item) => preFilterValue.includes(item));
                set(filters, preFilterKey, limitedValue);
            }
        } else {
            set(filters, preFilterKey, preFilterValue);
        }
    });
    return filters;
};

export { applyPreFilters };
