import { AssetType, AssetV2 } from 'types/asset.type';
import { CreativeAsset } from 'components/creative-management-v2/types/creative-management.type';
import { MediaAsset } from 'components/media-management/types/media-management.type';
import { TemplateAsset } from 'components/template-management/types/template-management.type';

const isAssetType = (asset: AssetV2<unknown, unknown>): AssetType | null => {
    switch (asset.type) {
        case 'media':
        case 'creative':
        case 'template':
            return asset.type;
        default:
            return null;
    }
};

const isMediaAsset = (asset: AssetV2<unknown, unknown>): asset is MediaAsset => {
    return asset.type === 'media';
};

const isTemplateAsset = (asset: AssetV2<unknown, unknown>): asset is TemplateAsset => {
    return asset.type === 'template';
};

const isCreativeAsset = (asset: AssetV2<unknown, unknown>): asset is CreativeAsset => {
    return asset.type === 'creative';
};

export { isAssetType, isMediaAsset, isTemplateAsset, isCreativeAsset };
